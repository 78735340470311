/*************************************************************************************
 # Global
*************************************************************************************/

var $ = jQuery;

/*************************************************************************************
 # Doc Ready
*************************************************************************************/

$(document).ready(function(){

	$('a[href="#lightboxTrigger"]').on('click', function(e) {
		e.preventDefault();

		var parent = $(this).closest('.vc_column-inner');

		console.log(parent)
	
		parent.find('.ult-modal-input-wrapper img').trigger('click')
	})

	$('body').addClass('loaded');

	$('.testimonials .container').slick(
		{
			responsive: [
				{
				  breakpoint: 767,
				  settings: {
					arrows: false,
					autoplay: true,
					fade: true
				  }
				},
			]
		}
	);
	$('.hero-mobile .hero-cards').slick({
		slidesToShow: 3,
		slidesToScroll: 3,
		infinite : false,
		dots     : false,
		arrows   : true,
		prevArrow: '<button type="button" class="slick-prev">Next<i class="fas fa-chevron-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next">Next<i class="fas fa-chevron-right"></i></button>',
		responsive: [
			{
			  breakpoint: 767,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 2
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			  }
			}
		]
	});

	// var menu = new Mmenu('#mobileMenu');
	// var api = menu.API;

	// api.bind( "open:finish", function() {
	// 	// setTimeout(function() {
	// 		jQuery(".hamburger").addClass( "is-active" );
	// 	// });
	// });
	// api.bind( "close:finish", function() {
	// 	// setTimeout(function() {
	// 		jQuery(".hamburger").removeClass( "is-active" );
	// 	// });
	// });

	// $('#openMobileMenu').on('click', () => {
	// 	api.open();
	// })

	const headerSearch = new HeaderSearch('.menu-item__search');
	const mobileSearch = new HeaderSearch('.mobile-wrapper .menu-item__search');
	const toggleChildren = new ToggleChildren('aside .menu-item-has-children');
	const mobileSidebar = new MobileSidebar('.mobile-sidebar');
});

$(window).resize(function(){
	if ($(window).width() >= 1200) {
		destroyMobileMenu();
	}
});


class HeaderSearch {
	constructor(selector) {
		this.selector = selector;
		this.element = document.querySelector(selector)
		this.$el = $(this.element);
		this.$trigger = this.$el.find('.search-icon');
		this.active = false;
		this.backdrop = this.backdrop = new Backdrop(document.querySelector('body'));

		this.listen();
	}

	listen() {
		this.$trigger.on('click', () => {
			if (this.active) {
				this.deactivate();
			} else {
				this.activate();
			}
		});
	}

	activate() {
		this.$el.addClass('is-active');
		this.backdrop.open();
		this.active = true;
	}

	deactivate() {
		this.$el.removeClass('is-active');
		this.backdrop.close();
		this.active = false;
	}
}

class HomepageHeroOverlay {
	constructor(selector) {
		this.selector = selector;
		this.element = document.querySelector(selector)
		this.$el = $(this.element);
		this.$trigger = this.$el.find('.hero-middle__trigger');
		this.active = false;

		this.listen();
	}

	listen() {
		this.$trigger.on('mouseover', () => {
			if (!this.active) {
				this.activate();
			}
		});

		if (this.$trigger.length > 0) {
			const $logo = $('.main-nav__middle.logo a');

			$logo.on('click', (e) => {
				if (this.active) {
					e.preventDefault();

					this.deactivate();
				}
			})
		}
	}

	activate() {
		this.$el.addClass('overlay-active');
		this.active = true;
	}

	deactivate() {
		this.$el.removeClass('overlay-active');
		this.active = false;
	}
}

class MobileSidebar {
	constructor(element, id) {
		this.element = element;
		this.id = id;
		this.$el = $(this.element);
		this.$trigger = this.addTrigger();
		this.$children = this.$el.find('.menu-cybersecurity-sidebar-container');
		this.active = false;

		this.listen();
	}

	addTrigger() {
		let trigger = $(`
			<span class="trigger"><i class="fas fa-caret-down"></i></span>
		`);

		this.$el.find('.widget-title').prepend(trigger);

		return trigger;
	}

	listen() {
		this.$el.on('click', '.widget-title', (e) => {
			e.preventDefault();
			
			if (this.active) {
				this.deactivate();
			} else {
				this.activate();
			}
		});
	}

	activate() {
		this.$el.addClass('is-open');
		this.active = true;
	}

	deactivate() {
		this.$el.removeClass('is-open');
		this.active = false;
	}
}

class Toggle {
	constructor(element, id) {
		this.element = element;
		this.id = id;
		this.$el = $(this.element);
		this.$trigger = this.addTrigger();
		this.$children = this.$el.find('.sub-menu');
		this.active = false;

		this.addClasses();
		this.addIds();
		this.listen();
	}

	addClasses() {
		this.$el.addClass('toggle-children')
	}

	addIds() {
		this.$trigger.attr('data-trigger-id', this.id);
		this.$children.attr('data-trigger-id', this.id);
	}

	addTrigger() {
		let trigger = $(`
			<span class="toggle-trigger"><i class="fas fa-caret-down"></i></span>
		`);

		this.$el.find('> a').prepend(trigger);

		return trigger;
	}

	listen() {
		this.$el.on('click', '> a', (e) => {
			e.preventDefault();
			
			if (this.active) {
				this.deactivate();
			} else {
				this.activate();
			}
		});
	}

	activate() {
		this.$el.addClass('toggle-active');
		this.active = true;
	}

	deactivate() {
		this.$el.removeClass('toggle-active');
		this.active = false;
	}
}

class ToggleChildren {
	constructor(selector) {
		this.selector = selector;
		this.elements = document.querySelectorAll(selector)
		this.toggles = this.createToggles(this.elements);
	}

	createToggles() {
		let toggles = [];

		for (let index = 0; index < this.elements.length; index++) {
			const element = this.elements[index];

			toggles.push(new Toggle(element, index));
		}

		return toggles;
	}
}

function Backdrop(containerElement) {
	this.containerElement = containerElement;
	this.$el = jQuery(`<div class="ydop-backdrop"></div>`);
	this.active = false;

	this.render();
	this.listen();
}


Backdrop.prototype = {
	render: function() {
		jQuery(this.containerElement).append( this.$el );
	},

	listen: function() {
		this.$el.on('click', () => {
			this.emit('close');
			this.close();
		})
	},

	emit(name) {
		var event = new CustomEvent('ydopBackdrop:' + name, {
			bubbles: true
		});
		this.$el[0].dispatchEvent(event);
	},

	open: function() {
		document.querySelector('html').classList.add('is-locked');
		this.$el.addClass('is-active');
		this.active = true;
	},

	close: function() {
		document.querySelector('html').classList.remove('is-locked');
		this.$el.removeClass('is-active');
		this.active = false;
	}
}

/*************************************************************************************
 # Functions
*************************************************************************************/
mmenuInit();

            //	Create the sticky header.
            new Mhead('#masthead', {
                // pin: 0,
                // unpin: 0,
                // tolerance: 5
            });
function mmenuInit() {

	var $menu = jQuery("#mobileMenu").mmenu({
		"extensions": [
			"position-left",
		],
		"pageScroll": true,
		"navbar": {
		},
		},
		{
			// configuration
			offCanvas: {
				moveBackground: false,
				page: {
					selector: "#page"
				}
			},
			clone: false
	});

	var $icon = jQuery(".hamburger");

	var API = $menu.data( "mmenu" );

	$icon.on( "click", function() {
		API.open();
	});

	API.bind( "open:finish", function() {
		setTimeout(function() {
			jQuery(".hamburger").addClass( "is-active" );
		}, 100);
	});
	API.bind( "close:finish", function() {
		setTimeout(function() {
			jQuery(".hamburger").removeClass( "is-active" );
		}, 100);
	});
}


function bussinessFilter() {


	$('body').on('click', '.filter-button', function(){

		var dataFilter = $(this).attr('data-filter');

		var baseurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
		var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?service=' + dataFilter;


		$('.filter-button').removeClass('active');
		$(this).addClass('active');

		$(".faculty__filter select").val(dataFilter);

		if(dataFilter == 'all') {
			$('.business-cards__card').addClass('active');
		} else {
			$('.business-cards__card').removeClass('active');

			$('.business-cards__card').each(function(){

				var attr = $(this).attr('data-filter');
				var array = attr.split(',');

				if(array.includes(dataFilter)) {
					$(this).addClass('active');
				}
			});
		}

		if(dataFilter != 'all') {
			window.history.pushState({ path: newurl }, '', newurl);
		} else {
			window.history.pushState({ path: baseurl }, '', baseurl);
		}

	});


	$('body').on('change', '.faculty__filter select', function(){

		var dataFilter = $(this).val();

		var baseurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
		var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?faculty=' + dataFilter;


		$('.faculty__filter button').removeClass('active');
		$('.faculty__filter button[data-filter="'+dataFilter+'"]').addClass('active');


		if(dataFilter == 'all') {
			$('.faculty__card').addClass('active');
		} else {
			$('.faculty__card').removeClass('active');

			$('.faculty__card').each(function(){

				var attr = $(this).attr('data-filter');
				var array = attr.split(',');

				if(array.includes(dataFilter)) {
					$(this).addClass('active');
				}
			});
		}

		if(dataFilter != 'all') {
			window.history.pushState({ path: newurl }, '', newurl);
		} else {
			window.history.pushState({ path: baseurl }, '', baseurl);
		}

	});

	filterBusinessesByURLParam();
}

function mobileMenu() {
	$('body').on('click', '.hamburger', function(){

		if ($("body").hasClass("active-scroll")) {

			destroyMobileMenu();

		} else {

			$("body").addClass("active-scroll"), setTimeout(function() {
				$("body").addClass("active-fixed")
			}, 450);
			$('.mobile-nav').addClass('active');
			$(this).addClass('active');

		}
	});
}

function destroyMobileMenu() {
	$("body").removeClass("active-scroll"), setTimeout(function() {
		$("body").removeClass("active-fixed")
	}, 450);
	$('.mobile-nav').removeClass('active');
	$('.hamburger').removeClass('active');
}

function filterBusinessesByURLParam() {
	// this is required by the cache put in place
	var query = window.location.search;
	var key = 'service=';

	if (query) {
		var selectedService = query.substring(query.indexOf(key) + key.length);

		if (selectedService) {
			$('.business-listings__filters .filter-button[data-filter="' + selectedService + '"]').trigger('click');
		}
	}
}